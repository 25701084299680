<template>
  <div
    class="pages"
    v-loading="loading"
    element-loading-text="电子书加载中···"
    element-loading-background="rgba(0, 0, 0, .1)"
  >
    <div class="book" :class="isMobile ? 'phone' : 'browser'">
      <!-- 目录 -->

      <div class="left" :style="{ width: isShowLeft ? '15%' : '0px' }">
        <div class="title">
          <h3>{{ title }}</h3>
        </div>
        <div v-for="(item, index) in EBookData" :key="item.id">
          <!-- @click="HangChang(index, item)" -->

          <el-tree
            :ref="'tree' + index"
            accordion
            highlight-current
            :data="item.treeData"
            :props="defaultProps"
            node-key="id"
            @node-click="handleNodeClick(index, $event, item.id)"
          >
            <!-- :default-expanded-keys="treeExpandData"
              :default-checked-keys="checkedList" -->
            <span
              slot-scope="{ node }"
              class="custom-tree-node showname"
              :title="node.label"
              v-text="node.label"
            >
            </span>
          </el-tree>
        </div>
      </div>
      <div class="right" ref="right">
        <img
          ref="zkimg"
          @click="onShowLeft"
          :title="isShowLeft ? '关闭目录' : '展开目录'"
          src="../../assets/image/展开菜单.png"
          class="zk-img"
        />
        <div class="book_box">
          <div class="img_box">
            <!-- <img
              src="../../assets/image/view.png"
              class="view"
              v-if="pageIndex >= 0 && isViewShow"
              @click="viewPic"
            /> -->
            <span></span>
            <el-input
              class="search"
              v-model="keyword"
              @keyup.enter.native="handlerSearch()"
              placeholder="输入关键字"
              v-show="EBookData.length !== 0"
            >
              <template #suffix>
                <img
                  style="cursor: pointer"
                  @click="handlerSearch()"
                  class="img"
                  src="../../assets/image/fzg_ss.png"
                />
              </template>
            </el-input>
            <span></span>
          </div>
          <div class="book" ref="bodycontent" v-show="EBookData.length !== 0">
            <!-- 电子书 -->
            <div
              v-dragscroll
              class="body-content"
              :style="{
                width: pageWidth + 'px',
                height: pageHeight + 'px',
              }"
            >
              <div
                style="z-index: -1"
                class="dropBox"
                ref="dropBox"
                @contextmenu.prevent="dropclick"
              >
                <div class="dropwindow" ref="dropopen" id="dropopen">
                  <div class="drop-list" @click="cancelCon">取消选区</div>
                  <div class="drop-list" @click="copyText">生成段落</div>
                </div>
              </div>
              <div id="magazine" @dblclick="turnZoom" ref="magazine">
                <div
                  style="width: 100%; height: 100%"
                  v-for="(item, index) in allPages"
                  :key="`test_${index}`"
                  :data-index="index"
                  class="1"
                >
                  <div class="text1" ref="text1">
                    <div
                      style="width: 100%; height: 100%"
                      class="2"
                      v-if="item == ''"
                    ></div>
                    <auth-pic
                      v-else
                      style="width: 100%; height: 100%; margin: auto"
                      :auth-src="item"
                      :index="index"
                      :data-index="index"
                    ></auth-pic>
                  </div>
                </div>
              </div>
            </div>
            <div
              ref="btn_1"
              @mouseenter="onmouseenter"
              @mouseleave="onmouseleave"
              @click="onLeftClick"
              class="left_btn"
              v-show="searchResList.length > 10 && isBottomShow && isShowImg"
            >
              <i class="el-icon-arrow-left"></i>
            </div>
            <div
              ref="btn_2"
              @click="onRightClick"
              @mouseenter="onmouseenter"
              @mouseleave="onmouseleave"
              class="right_btn"
              v-show="searchResList.length > 10 && isBottomShow && isShowImg"
            >
              <i class="el-icon-arrow-right"></i>
            </div>
            <!-- 缩略图 -->
            <div
              class="box"
              v-show="searchResList.length && isBottomShow && isShowImg"
            >
              <ul
                class="image_box"
                ref="image_box"
                @mouseenter="onmouseenter"
                @mouseleave="onmouseleave"
                @click="onImgClick"
              >
                <li v-for="v in searchResList" :key="v.id" ref="li">
                  <img
                    :src="`/api/fileinfo/ebook/GetEBookPageThumbnail?fileName=${v.pictureFullName}&pageIndex=${v.pageIndex}`"
                    :data-page-index="v.pageIndex"
                    :data-id="v.ebookID ? v.ebookID : v.eBookID"
                    :data-location="JSON.stringify(v)"
                    alt=""
                  />
                </li>
                <img
                  @load="onload"
                  v-if="searchResList.length"
                  style="display: none"
                  :src="`/api/fileinfo/ebook/GetEBookPageForOriImg?fileName=${searchResList[0].pictureFullName}&pageIndex=${searchResList[0].pageIndex}`"
                />
              </ul>
            </div>

            <!-- 操作 -->
            <div :class="isMobile ? 'pagination' : 'pagination1'">
              <div
                class="pagination-item el-icon-d-arrow-left"
                @click="firstPage"
              ></div>
              <div
                class="pagination-item el-icon-arrow-left"
                @click="prePage"
              ></div>
              <div class="pagination-input">
                <!-- type="number" -->
                <el-input
                  ref="pageInput"
                  :placeholder="placeholderStr"
                  v-model="paginationNum"
                  @keydown.enter.native="blurOut"
                ></el-input>
                <!-- @keyup.enter.native="blurOut" -->
                <!-- <span>
                    {{ placeholderStr }}
                  </span> -->
              </div>
              <div
                class="pagination-item el-icon-arrow-right"
                @click="nextPage"
              ></div>
              <div
                class="pagination-item el-icon-d-arrow-right"
                @click="lastPage"
              ></div>
              <div
                class="pagination-item el-icon-rank"
                title="双击电子书可直接放大缩小"
                @click="turnZoom"
              ></div>
            </div>
          </div>
        </div>
        <div class="text_box" v-show="!loading">
          <div class="text">
            <div class="operation">
              <div>书写顺序：</div>
              <el-radio @input="onRadioChange" v-model="radio" label="1"
                >横排</el-radio
              >
              <el-radio @input="onRadioChange" v-model="radio" label="2"
                >竖排</el-radio
              >
            </div>
            <div :class="[radio === '1' ? 'test2' : 'test1']">
              <span
                id="articleDetailsRight"
                v-html="content.rightModel.content"
              >
              </span>
              <!-- v-html="highlight(content.rightModel.content)" -->
              <span style="visibility: hidden">☂</span>
              <br />
              <span
                id="articleDetailsLeft"
                v-html="content.leftModel.content"
              ></span>
            </div>
          </div>
          <!-- <div class="btn">
            <el-button @click="qrClick">二维码获取</el-button>
            <el-button @click="onClick">引用生成获取</el-button>
          </div> -->
        </div>
      </div>
    </div>
    <div style="z-index: 999999999">
      <el-dialog
        id="dlog"
        :visible.sync="dialogTableVisible"
        @close="onClose"
        :modal="true"
        :close-on-click-modal="false"
      >
        <div
          style="height: 60vh; display: flex; justify-content: center"
          @contextmenu.prevent
        >
          <a
            :href="`/api/fileinfo/image/GetOriImage?filename=${pictureFullName}&ocrSource=1`"
            target="_blank"
          >
            <el-image
              @load="onload"
              @error="onerror"
              fit="contain"
              :src="`/api/fileinfo/image/GetOriImage?filename=${pictureFullName}&ocrSource=1`"
              style="width: 100%; height: 100%"
            />
          </a>
        </div>
        <div
          class="el-dialog__footer"
          style="display: flex; justify-content: center; align-items: center"
        >
          <el-button @click="Before">上一页</el-button>
          <el-button @click="After">下一页</el-button>
        </div>
      </el-dialog>
    </div>
    <rangeDialog
      :rangeDialogVisible.sync="rangeDialogVisible"
      :content="rangeContent"
    />
  </div>
</template>
  
  <script>
import { Loading } from "element-ui";
import _ from "lodash";
import authPic from "@/components/aut/authPic";
import turn from "@/utils/turn.js";
import $ from "jquery";
import {
  getEbookRangeContent,
  getEbookBaseData,
  beforOrNext,
  viewPic,
  searchContent,
  getEbookNewContent,
  getEbook,
} from "@/api/literature.js";
// import QuotePage from "./components/quotePage.vue";
// import QrcodePage from "./components/qrcodePage.vue";
import rangeDialog from "./components/rangeContent.vue";

export default {
  components: {
    rangeDialog,
    authPic,
    // QuotePage,
    // QrcodePage,
  },
  data() {
    return {
      timer: null,
      isMobile: null, // 默认浏览器环境
      isShowImg: true,
      isDisable: true,
      one: {},
      two: {},
      num: 1,
      tempMessage: "", //复制内容
      isDrawing: true,
      rangeContent: {},
      rangeDialogVisible: false,
      index: 0,
      scale: 0,
      positionObj: {},
      scaleY: 0,
      scaleX: 0,
      naturalWidth: 0,
      naturalHeight: 0,
      proofreadList: [],
      title: "",
      cutWidth: 250,
      isBottomShow: true,
      isViewShow: false,
      totalPage: "",
      genealogyOCRInfoID: "",
      pictureFullName: "",
      dialogTableVisible: false,
      ebookId: "",
      radio: "2",
      isShowLeft: true,
      // treeExpandData: [],
      pIndex: 0,
      zoom: 0,
      divWidth: 0,
      divHeight: 0,
      oWidth: "",
      oHeight: "",
      locations: [],
      imgIndex: 0,
      searchResList: [],
      sourceID: "",
      downContentUrl: "",
      pageIndex: 1,
      qrCodeDialogVisible: false,
      quoteDialogVisible: false,
      content: {
        rightModel: "",
        leftModel: "",
      },
      keyword: "", // 搜索关键字
      defaultProps: {
        children: "children",
        label: "title",
      },
      loading: false,
      originTitle: "", //原封面
      /* 预览电子书 */
      searchData: [], //搜索结果
      pagesData: [], //所有页面
      searchKeyWord: "", //检索关键词
      scaleValue: 1, //缩小 、放大
      paginationNum: null, //跳转到特定页面
      allPages: [], //电子书图片列表
      translateX: 0, //移动值
      transData: [], //检索展示的页面
      scaleIndex: 1,
      /* 电子书 */
      pageWidth: 0,
      pageHeight: 0,
      mPageCount: 0, //电子书总页数数量
      ebookIndex: 0, //当前对应的电子书
      activeId: null, //当前对应的电子书id子级的id
      eBookDialogVisible: false, //修改电子书封面弹窗
      EBookData: [], //电子书列表
      EBookItem: null,
      ePageIndex: 1,
      ePageSize: 50,
      eTotals: 0,
      placeholderStr: "",
      isSingle: true, //是否单面
      ispicType: "", //电子书类型
      outputWatermark: "",
      flag: false,
      bookInfo: {},
      leftCitation: "",
      rightCitation: "",
      rightIndex: "",
      leftIndex: "",
      rightTitle: "",
      leftTitle: "",
      picIndex: "",
      loadingInstance: "",
      options: {
        fullscreen: true,
        lock: true,
        text: "Loading",
        target: "#dlog",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      },
    };
  },
  async created() {
    this.sourceID = this.$route.query.sourceID;
    // await this.getBaseData();
    await this.getEBook(this.sourceID);
    if (this.$route.query.memberInEbook) {
      this.searchResList = this.$store.state.memberInEbooks;
    }
  },
  async mounted() {
    this.moveleft();
    $(window).bind("keydown", function (e) {
      if (e.keyCode == 37) $("#magazine").turn("previous");
      else if (e.keyCode == 39) $("#magazine").turn("next");
    });
    // window.onload = () => {
    //   let answerRight = document.getElementById("articleDetailsRight");
    //   answerRight.addEventListener("copy", (e) => {
    //     this.setClipboardText(e, "right");
    //   });
    //   let answerLeft = document.getElementById("articleDetailsLeft");
    //   answerLeft.addEventListener("copy", (e) => {
    //     this.setClipboardText(e, "left");
    //   });
    // };
  },
  beforeDestroy() {
    // window.removeEventListener("copy", this.setClipboardText());
    $("#magazine").turn("destroy").remove();
    // window.removeEventListener("keydown", this.moveleft);
  },
  watch: {
    locations: {
      handler(newVal) {
        if (newVal) {
          this.renderSearchMask(newVal, this.pIndex);
        }
      },
      immediate: true,
      deep: true,
    },
    async flag(newVal) {
      // 默认选中展开
      if (this.$route.query.checkedId) {
        try {
          let key = "tree" + this.$route.query.bookIndex;
          this.$refs[key][0].setCurrentKey(this.$route.query.checkedId);
          console.log(this.$refs[key][0], " this.$refs[key][0]");
          const selected = this.$refs[key][0].getCurrentNode();
          console.log(selected, "selected");
          if (
            this.$refs[key][0].getNode(selected) &&
            this.$refs[key][0].getNode(selected).parent
          ) {
            this.expandParents(this.$refs[key][0].getNode(selected).parent);
          }
        } catch (error) {
          console.log(error, "error");
        }
      }
      if (this.$route.query.index) {
        this.handleNodeClick(
          this.$route.query.bookIndex,
          this.$route.query.index,
          this.$route.query.bookId
        );
      }
      if (this.$route.query.memberInEbook) {
        let index = this.EBookData.findIndex(
          (v) => v.id === this.$route.query.bookId
        );
        if (index !== -1) {
          let that = this;
          this.pIndex = +that.searchResList[0].pageIndex + 3;
          $("#magazine").turn("destroy");
          await this.HangChang(index, this.EBookData[index], function () {
            $("#magazine").turn("page", +that.searchResList[0].pageIndex + 3);
            console.log(that.searchResList[0], " that.searchResList[0]");
            that.locations = that.searchResList[0];
          });
        }
      }
      if (this.$route.query.Keyword) {
        this.keyword = this.$route.query.Keyword;
        this.handlerSearch(this.$route.query.Keyword);
      }
    },
  },
  methods: {
    // 取消选区
    cancelCon() {
      this.one = {};
      this.two = {};
      this.num = 1;
      let dropopen = this.$refs.dropopen;
      dropopen.style.display = `none`;
      let dropbox = this.$refs.dropBox;
      dropbox.style.left = `-1px`;
      dropbox.style.top = 0;
      dropbox.style.width = 0;
      dropbox.style.height = 0;
      dropbox.style.zIndex = `-1`;
    },
    // 局部段落
    async copyText() {
      const loading = this.$loading({
        lock: true,
        target: ".body-content",
        text: "加载中...",
        spinner: "el-icon-loading",
      });
      try {
        const res = await getEbookRangeContent({
          sourceID: this.sourceID,
          index: this.index,
          id: this.ebookId,
          region: {
            location: {
              x: parseInt(this.positionObj.x / this.scaleX),
              y: parseInt(this.positionObj.y / this.scaleY),
            },
            size: {
              width: parseInt(this.positionObj.width / this.scaleX) - 20,
              height: parseInt(this.positionObj.height / this.scaleY) - 20,
            },
            x: parseInt(this.positionObj.x / this.scaleX),
            y: parseInt(this.positionObj.y / this.scaleY),
            width: parseInt(this.positionObj.width / this.scaleX) - 20,
            height: parseInt(this.positionObj.height / this.scaleY) - 20,
          },
        });
        this.rangeContent = res;
        this.$refs.dropopen.style.display = "none";
        if (!this.rangeContent.content)
          return this.$message.info("暂无可生成的内容");
        // const pattern = /\[[^\]]*\d[^\]]*\]/g;
        // this.rangeContent.content = this.rangeContent.content.replace(
        //   pattern,
        //   "<img title='$&' alt='$&' src='http://112.111.62.151:9000/api/proofread/WordBuilder/GetWordImageByCode?code=$&' style='width: 23px; height: 23px'/><span style='display: none'>$&</span>"
        // );
        this.rangeDialogVisible = true;
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    // 右击画框
    dropclick(e) {
      let rightbox = this.$refs.dropopen;
      rightbox.style.right = "";
      rightbox.style.left = "";

      rightbox.style.top = e.offsetY + "px";
      if (this.positionObj.x + e.offsetX + 150 >= this.pageWidth / 2) {
        rightbox.style.right = this.positionObj.width - e.offsetX + "px";
      } else {
        rightbox.style.left = e.offsetX + "px";
      }
      rightbox.style.display = "flex";
    },
    // 画框
    moveleft(e) {
      let that = this;
      let ele = that.$refs.magazine;
      ele.addEventListener("mousedown", (e) => {
        if (e.button !== 0) return;
        this.$refs.dropopen.style.display = "none";
        this.isShowImg = false;
        // 移动
        // if (e.ctrlKey) {
        if (this.isDisable) {
          e?.stopPropagation();
          that.cancelCon();
          that.isDrawing = false;
          // 减去边框
          // that.scale = (e.target.offsetWidth - 2) / that.naturalWidth;
          that.scaleX =
            e.target?.querySelector("img")?.width /
            e.target?.querySelector("img")?.naturalWidth;
          that.scaleY =
            e.target?.querySelector("img")?.height /
            e.target?.querySelector("img")?.naturalHeight;

          console.log(that.scaleX, that.scaleY);
          //获取鼠标开始拖动的起始位置
          let a = {};
          a.x = e.offsetX;
          a.y = e.offsetY;

          let offsetWidth = e.target.offsetWidth;
          let offsetHeight = e.target.offsetHeight;
          // 可视区坐标
          let startPos = {};
          startPos.x = e.clientX;
          startPos.y = e.clientY;

          let index = e.target.dataset?.index;
          let width = "";
          if (index && index % 2 === 0) {
            width = e.target.offsetWidth;
          } else if (!index) {
            return;
          } else {
            width = 0;
          }
          this.index = index - 2;

          //鼠标按下移动时动态获取鼠标位置
          ele.addEventListener("mousemove", move);

          function move(e) {
            let element = that.$refs.dropBox;
            let offsetX = e.offsetX;
            let offsetY = e.offsetY;
            if (e.offsetX >= offsetWidth - 2) {
              offsetX = offsetWidth;
              that.positionObj = {
                x: a.x,
                y: a.y,
                width: offsetX - a.x,
                height: e.offsetY - a.y,
              };
              element.style.width = offsetX - a.x - 5 + "px";
              element.style.height = e.offsetY - a.y - 5 + "px";
              element.style.left = a.x + width + "px";
              element.style.top = a.y + "px";
              element.style.border = `1px dashed red`;
              element.style.position = `absolute`;
              element.style.zIndex = `999999`;
              that.isDrawing = true;
              ele.removeEventListener("mousemove", move);
            } else if (e.offsetY >= offsetHeight - 10) {
              offsetY = offsetHeight;
              that.positionObj = {
                x: a.x,
                y: a.y,
                width: e.offsetX - a.x,
                height: offsetY - a.y,
              };
              element.style.width = e.offsetX - a.x - 5 + "px";
              element.style.height = offsetY - a.y - 5 + "px";
              element.style.left = a.x + width + "px";
              element.style.top = a.y + "px";
              element.style.border = `1px dashed red`;
              element.style.position = `absolute`;
              element.style.zIndex = `999999`;
              that.isDrawing = true;
              ele.removeEventListener("mousemove", move);
            } else {
              that.positionObj = {
                x: a.x,
                y: a.y,
                width: offsetX - a.x,
                height: e.offsetY - a.y,
              };
              element.style.width = offsetX - a.x - 5 + "px";
              element.style.height = e.offsetY - a.y - 5 + "px";
              element.style.left = a.x + width + "px";
              element.style.top = a.y + "px";
              element.style.border = `1px dashed red`;
              element.style.position = `absolute`;
              element.style.zIndex = `999999`;
            }

            if (e.offsetX < a.x || e.offsetY < a.y) {
              if (e.offsetX <= 2 || e.offsetY <= 2) {
                that.positionObj = {
                  x: offsetX,
                  y: e.offsetY,
                  width: Math.abs(offsetX - a.x),
                  height: Math.abs(e.offsetY - a.y),
                };
                element.style.width = Math.abs(offsetX - a.x) - 5 + "px";
                element.style.height = Math.abs(e.offsetY - a.y) - 5 + "px";
                element.style.left = e.offsetX + width + "px";
                element.style.top = e.offsetY + "px";
                element.style.border = `1px dashed red`;
                element.style.position = `absolute`;
                element.style.zIndex = `999999`;
                ele.removeEventListener("mousemove", move);
              } else {
                that.positionObj = {
                  x: offsetX,
                  y: e.offsetY,
                  width: Math.abs(offsetX - a.x),
                  height: Math.abs(e.offsetY - a.y),
                };
                element.style.width = Math.abs(offsetX - a.x) - 5 + "px";
                element.style.height = Math.abs(e.offsetY - a.y) - 5 + "px";
                element.style.left = e.offsetX + width + "px";
                element.style.top = e.offsetY + "px";
                element.style.border = `1px dashed red`;
                element.style.position = `absolute`;
                element.style.zIndex = `999999`;
              }
            }
          }

          //鼠标放下时，停止生成画框
          ele.addEventListener(
            "mouseup",
            function () {
              that.isDrawing = true;
              that.isShowImg = true;
              ele.removeEventListener("mousemove", move);
            },
            {
              once: true,
            }
          );
          // } else {
          return;
          // }
        }
      });
    },
    // 基础数据
    async getBaseData() {
      const res = await getEbookBaseData(this.$route.query.bookId);
      this.setBookInfo(res);
      this.title = res.title;
    },
    async setBookInfo(data) {
      this.bookInfo = {
        contributor: data.contributor ?? "", // 责任者
        title: data.title ?? "", // 题名
        placeOfPublication: data.placeOfPublication ?? "", // 出版地
        publisher: data.publisher ?? "", // 出版者
        dateOfPublication: data.dateOfPublication ?? "", // 出版日期
        seriesArea: data.seriesArea ?? "",
        surname: data.surname ?? "",
        hall: data.hall ?? "",
      };
    },
    // 高亮
    highlight(text) {
      if (!text) return;
      let tempList = [];
      if (this.searchResList.length) {
        for (let i = 0; i < this.searchResList.length; i++) {
          for (let j = 0; j < this.searchResList[i].words.length; j++) {
            tempList.push(this.searchResList[i].words[j].word);
          }
          tempList = [...new Set(tempList)];
          if (tempList.length === this.keyword.length) {
            break;
          }
        }
      }
      let str = text;
      if (this.keyword) {
        const req = new RegExp(`(${tempList.join("|")})`, "gi");
        // 字符串的替换
        str = text.replace(req, '<span style="color:red">$1</span>');
      }
      return str;
    },
    expandParents(node) {
      node.expanded = true;
      if (node.parent) {
        this.expandParents(node.parent);
      }
    },
    async Before() {
      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await beforOrNext({
          genealogyOCRInfoID: this.genealogyOCRInfoID,
          opType: 0,
        });
        this.pictureFullName = res.pictureFullName;
        this.genealogyOCRInfoID = res.genealogyOCRInfoID;
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
      } finally {
        this.loadingInstance.close();
      }
    },
    async After() {
      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await beforOrNext({
          genealogyOCRInfoID: this.genealogyOCRInfoID,
          opType: 1,
        });
        this.genealogyOCRInfoID = res.genealogyOCRInfoID;
        this.pictureFullName = res.pictureFullName;
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
      } finally {
        this.loadingInstance.close();
      }
    },
    onClose() {
      this.dialogTableVisible = false;
      this.isBottomShow = true;
      // this.pictureFullName = "";
    },
    onload() {
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        if (this?.loadingInstance?.close) {
          this.loadingInstance.close();
        }
      });
    },
    onerror() {
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        if (this?.loadingInstance?.close) {
          this.loadingInstance.close();
        }
      });
    },
    async viewPic() {
      this.isBottomShow = false;
      if (this.pageIndex <= 0) return;
      try {
        const res = await viewPic({
          ebookId: this.ebookId,
          pageIndex: this.pageIndex,
        });
        this.pictureFullName = res.pictureFullName;
        this.genealogyOCRInfoID = res.genealogyOCRInfoID;
        this.dialogTableVisible = true;
      } catch (error) {}
    },
    // 判断是否在最后
    validationEnd(str, appoint) {
      str = str.toLowerCase(); //不区分大小写：全部转为小写后进行判断

      var start = str.length - appoint.length; //相差长度=字符串长度-特定字符长度
      var char = str.substr(start, appoint.length); //将相差长度作为开始下标，特定字符长度为截取长度

      if (char == appoint) {
        //两者相同，则代表验证通过
        return true;
      }
      return false;
    },
    // 引用生成
    onClick() {
      if (this.content.rightModel.content) {
        this.content.rightModel.content = this.content.rightModel.content
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』");
      }
      if (this.content.leftModel.content) {
        this.content.leftModel.content = this.content.leftModel.content
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』")
          .replace(/﹁/g, "「")
          .replace(/﹂/g, " 」");
      }
      this.quoteDialogVisible = true;
    },
    // 复制携带版权信息
    setClipboardText(event, position) {
      let leftIndex = this.leftIndex == 0 ? "" : `第${this.leftIndex}页`;
      let rightIndex = this.rightIndex == 0 ? "" : `第${this.leftIndex}页`;
      event?.preventDefault(); //阻止元素发生默认的行为
      const node = document.createElement("div");
      node.appendChild(window.getSelection().getRangeAt(0).cloneContents());
      this.leftCitation = `[序号]${this.bookInfo.contributor}${
        this.bookInfo.contributor ? "." : ""
      }${this.bookInfo.title}${this.bookInfo.title ? "." : ""}${
        this.bookInfo.seriesArea || ""
      }[M].${this.bookInfo.surname}${this.bookInfo.surname ? "." : ""}
            ${this.bookInfo.hall}${this.bookInfo.hall ? "." : ""}${
        this.bookInfo.placeOfPublication
      }${this.bookInfo.placeOfPublication ? "." : ""}${
        this.bookInfo.publisher
      }${this.bookInfo.publisher ? "." : ""}${this.bookInfo.dateOfPublication}${
        this.bookInfo.dateOfPublication ? ":" : ""
      }${this.leftTitle}${leftIndex}`;

      this.rightCitation = `[序号]${this.bookInfo.contributor}${
        this.bookInfo.contributor ? "." : ""
      }${this.bookInfo.title}${this.bookInfo.title ? "." : ""}${
        this.bookInfo.seriesArea || ""
      }[M].${this.bookInfo.surname}${this.bookInfo.surname ? "." : ""}
            ${this.bookInfo.hall}${this.bookInfo.hall ? "." : ""}${
        this.bookInfo.placeOfPublication
      }${this.bookInfo.placeOfPublication ? "." : ""}${
        this.bookInfo.publisher
      }${this.bookInfo.publisher ? "." : ""}${this.bookInfo.dateOfPublication}${
        this.bookInfo.dateOfPublication ? ":" : ""
      }${this.rightTitle}${rightIndex}`;
      let htmlData;
      let textData;
      if (position === "left") {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText.replace(/\n/g, "") +
          `${this.leftCitation}` +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText.replace(/\n/g, "") +
          "\n" +
          "\n" +
          "---------------------------" +
          "\n" +
          "\n" +
          `${this.leftCitation}`;
      } else if (
        position === "right" &&
        node.innerText.includes("☂") &&
        !this.validationEnd(node.innerText, "☂")
      ) {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText.replace(/\n/g, "").replace(
            /☂/,

            "\n" +
              "---------------------------" +
              "\n" +
              this.rightCitation +
              "\n" +
              "\n"
          ) +
          "\n" +
          `${this.leftCitation}` +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText.replace(/\n/g, "").replace(
            /☂/,

            "\n" +
              "---------------------------" +
              "\n" +
              this.rightCitation +
              "\n" +
              "\n"
          ) +
          "\n" +
          "\n" +
          "---------------------------" +
          "\n" +
          "\n" +
          `${this.leftCitation}`;
      } else if (position === "right" && !node.innerText.includes("☂")) {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText.replace(/\n/g, "") +
          `${this.rightCitation}` +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText.replace(/\n/g, "") +
          "\n" +
          "\n" +
          "---------------------------" +
          "\n" +
          "\n" +
          `${this.rightCitation}`;
      } else if (
        position === "right" &&
        node.innerText.includes("☂") &&
        this.validationEnd(node.innerText, "☂")
      ) {
        htmlData =
          "<div>" +
          // node.innerHTML.replace(/\n/g, "") +
          node.innerText
            .replace(/\n/g, "")
            .replace(
              /☂/,
              "\n" +
                "---------------------------" +
                "\n" +
                this.rightCitation +
                "\n" +
                "\n"
            ) +
          "</div>";
        textData =
          // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
          node.innerText
            .replace(/\n/g, "")
            .replace(
              /☂/,
              "\n" +
                "---------------------------" +
                "\n" +
                this.rightCitation +
                "\n" +
                "\n"
            );
      }

      if (event.clipboardData) {
        event.clipboardData.setData("text/html", htmlData);
        //setData(剪贴板格式, 数据) 给剪贴板赋予指定格式的数据。返回 true 表示操作成功。
        event.clipboardData.setData("text/plain", textData);
      } else if (window.clipboardData) {
        //window.clipboardData的作用是在页面上将需要的东西复制到剪贴板上，提供了对于预定义的剪贴板格式的访问，以便在编辑操作中使用。
        return window.clipboardData.setData("text", textData);
      }
    },
    // 改变书写顺序
    onRadioChange(e) {
      if (this.content.rightModel.content) {
        this.content.rightModel.content = this.content.rightModel.content
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』")
          .replace(/﹁/g, "「")
          .replace(/﹂/g, " 」");
        // .replace(/︹/g, " ")
        // .replace(/︺/g, " ");
      }
      if (this.content.leftModel.content) {
        this.content.leftModel.content = this.content.leftModel.content
          .replace(/︵/g, "(")
          .replace(/︶/g, ")")
          .replace(/︽/g, "《")
          .replace(/︾/g, "》")
          .replace(/﹃/g, "『")
          .replace(/﹄/g, "』")
          .replace(/﹁/g, "「")
          .replace(/﹂/g, " 」");
        // .replace(/︹/g, " ")
        // .replace(/︺/g, " ");
      }
      this.radio = e;
    },
    // 目录展开收起
    onShowLeft() {
      if (this.isShowLeft) {
        this.isShowLeft = !this.isShowLeft;
        this.$refs.zkimg.style = "transform:  rotate(0deg);";
        this.$refs.right.style.width = "100%";
      } else {
        this.isShowLeft = !this.isShowLeft;
        this.$refs.right.style.width = "85%";
        this.$refs.zkimg.style = "transform:  rotate(180deg);";
      }
    },
    convertRect0(sRect) {
      var values = sRect.split(",");
      if (values.length == 4) {
        var rect = {
          x: parseInt(values[0]),
          y: parseInt(values[1]),
          width: parseInt(values[2]),
          height: parseInt(values[3]),
        };
        return rect;
      }
      return { x: 0, y: 0, width: 0, height: 0 };
    },
    renderSearchMask(data, pageIndex) {
      if (!data || !pageIndex) return;
      let that = this;
      if (data && pageIndex > 0) {
        var $page = $(".page-wrapper[page=" + pageIndex + "]", "#magazine");
        if (!$page.find("img").height() && !$page.find("img").width()) {
          console.log(
            !$page.find("img").height() && !$page.find("img").width()
          );
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            this.renderSearchMask(data, pageIndex);
          }, 1000);
        } else {
          clearInterval(this.timer);
          console.log(this.cutWidth, "cutWidth", data.width);
          console.log($page.find("img").width(), '$page.find("img").width()');
          var wr =
            $page.find("img").width() / ((data.width - this.cutWidth * 2) / 2);
          var hr = $page.find("img").height() / data.height;
          console.log(data.width, data.height, "data");
          console.log($page.find("img").height());
          console.log($page.find("img").width());
          console.log(wr, hr, "---");
          if ($page.length === 1) {
            var $mask = $(".ebook-search-page", $page);
            if ($mask.length == 1) {
              $mask.remove();
            }
            var html = '<div class="ebook-search-page">';
            html += "</div>";
            var $sp = $(html);
            var rect;
            html = "";
            let self = this;
            $.each(data.words, function () {
              // rect = that.convertRect0(this.location);
              if (
                this.location.x &&
                this.location.y &&
                this.location.width &&
                this.location.height
              ) {
                rect = {
                  x: parseInt(this.location.x),
                  y: parseInt(this.location.y),
                  width: parseInt(this.location.width),
                  height: parseInt(this.location.height),
                };
              } else {
                rect = { x: 0, y: 0, width: 0, height: 0 };
              }
              if (!data.isSingle) {
                if (this.pageIndex % 2 == 0) {
                  rect.x -= data.width / 2;
                } else {
                  if (that.EBookData[0].PageWidth == 4252) {
                  } else {
                    rect.x -= self.cutWidth;
                  }
                }
              }
              rect.x *= wr;
              rect.y *= hr;
              rect.width *= wr;
              rect.height *= hr;
              html =
                '<a class="ebook-search-mask" " title="' +
                this.word +
                '" style="display:inline-block;position: absolute;background: rgba(255, 255, 0, .5);left:' +
                rect.x +
                "px;top:" +
                rect.y +
                "px;width:" +
                rect.width +
                "px;height:" +
                rect.height +
                'px"></a>';
              $(html).data("data-bound", rect).appendTo($sp);
            });
            $sp.appendTo($page);
          }
        }
      }
    },
    onload(e) {
      this.oWidth = e.naturalWidth;
      this.oHeight = e.naturalHeight;
    },
    // 点击搜索结果
    async onImgClick(e) {
      let target = e.target;
      console.log(e.target.dataset, "xxx");
      if (target.nodeName === "IMG") {
        // 从世系树跳转过来
        // if (this.$route.query.memberInEbook) {
        //   this.pIndex = +target.dataset.pageIndex;
        // } else {
        this.pIndex = +target.dataset.pageIndex + 3;
        // }
        const index = this.EBookData.findIndex(
          (v) => v.id === target.dataset.id
        );
        if (index !== -1) {
          this.ebookId = target.dataset.id;
          let that = this;
          $("#magazine").turn("destroy");
          await this.HangChang(index, this.EBookData[index], function () {
            console.log(that.pIndex, "that.pIndex");
            $("#magazine").turn("page", that.pIndex);
            that.locations = JSON.parse(target.dataset.location);
          });
        }
      }
    },
    // 左箭头
    onLeftClick() {
      if (this.imgIndex === 0) return this.$message.info("已加载第一条");
      this.imgIndex--;
      this.$refs.image_box.style.transition = "left .5s ease 0s";
      this.$refs.image_box.style.left = this.imgIndex * 70 + "px";
    },
    // 右箭头
    onRightClick() {
      if (this.imgIndex >= this.searchResList.length - 10)
        return this.$message.info("已加载最后一条");
      this.imgIndex++;
      this.$refs.image_box.style.transition = "left .5s ease 0s";
      this.$refs.image_box.style.left = -this.imgIndex * 70 + "px";
    },
    // 搜索
    async handlerSearch(keyword = null) {
      if (this.$route.query.memberInEbook) {
        // 更换参数，防止点击缩略图报错
        this.$router.push({
          path: "/Ebook",
          query: {
            id: this.$route.query.id,
            sourceID: this.$route.query.sourceID,
            bookId: this.$route.query.bookId,
            type: this.$route.query.type,
          },
        });
      }

      const loading = this.$loading({
        lock: true,
        target: ".body-content",
        text: "加载中...",
        spinner: "el-icon-loading",
      });
      try {
        const res = await searchContent({
          sourceID: this.sourceID,
          key: keyword || this.keyword,
        });
        if (!res.length) return this.$message.info("暂无结果");
        // 根据 pageIndex 去重
        let hash = {};
        this.searchResList = res.reduce((prev, next) => {
          hash[next.pageIndex]
            ? ""
            : (hash[next.pageIndex] = true && prev.push(next));
          return prev;
        }, []);
        this.locations = this.searchResList[0];
        if (
          this.$route.query.pageIndex &&
          this.$route.query.keyword === keyword
        ) {
          return;
        }
        let index = this.EBookData.findIndex(
          (v) => v.id === this.searchResList[0].ebookID
        );
        if (index !== -1) {
          this.pIndex = this.searchResList[0].pageIndex + 3;
          $("#magazine").turn("destroy");
          await this.HangChang(index, this.EBookData[index]);
          this.$nextTick(() => {
            $("#magazine").turn("page", this.searchResList[0].pageIndex + 3);
            this.locations = this.searchResList[0];
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    qrClick() {
      this.downContentUrl =
        "http://" +
        window.location.host +
        "/phone?index=" +
        this.pageIndex +
        "&sourceID=" +
        this.sourceID +
        "&id=" +
        this.ebookId;
      this.qrCodeDialogVisible = true;
    },
    onmouseenter() {
      this.$refs.btn_1.style.opacity = "1";
      this.$refs.btn_2.style.opacity = "1";
    },
    onmouseleave() {
      this.$refs.btn_1.style.opacity = "0.1";
      this.$refs.btn_2.style.opacity = "0.1";
    },
    async getEBookContent(pageIndex) {
      if (pageIndex === -1) return;
      try {
        var loading = Loading.service({
          fullscreen: true,
          lock: true,
          text: "加载中……",
          target: ".text",
          spinner: "el-icon-loading",
          // background: "rgba(0, 0, 0, 0.3)",
        });
        const res = await getEbookNewContent({
          ebookId: this.ebookId,
          sourceID: this.sourceID,
          index: pageIndex,
        });
        this.content = res;

        // 异体字转换
        const pattern = /\[[^\]]*\d[^\]]*\]/g;
        if (this.content.rightModel.content) {
          this.content.rightModel.content =
            this.content.rightModel.content.replace(
              pattern,
              "<img title='$&' alt='$&' src='http://112.111.62.151:9000/api/proofread/WordBuilder/GetWordImageByCode?code=$&' style='width: 23px; height: 23px'/><span style='display: none'>$&</span>"
            );
        }
        if (this.content.leftModel.content) {
          this.content.leftModel.content =
            this.content.leftModel.content.replace(
              pattern,
              "<img title='$&' alt='$&' src='http://112.111.62.151:9000/api/proofread/WordBuilder/GetWordImageByCode?code=$&' style='width: 23px; height: 23px'/><span style='display: none'>$&</span>"
            );
        }

        // 高亮搜索
        if (
          (this.keyword || this.$route.query.memberInEbook) &&
          this.searchResList.length
        ) {
          let tempList = [];
          for (let i = 0; i < this.searchResList.length; i++) {
            for (let j = 0; j < this.searchResList[i].words.length; j++) {
              tempList.push(this.searchResList[i].words[j].word);
            }
            tempList = [...new Set(tempList)];
            if (tempList.length === this.keyword.length) {
              break;
            }
          }
          const req = new RegExp(`(${tempList.join("|")})`, "gi");
          // 字符串的替换
          if (this.content.rightModel.content) {
            this.content.rightModel.content =
              this.content.rightModel.content.replace(
                req,
                '<span style="color:red">$1</span>'
              );
          }
          if (this.content.leftModel.content) {
            this.content.leftModel.content =
              this.content.leftModel.content.replace(
                req,
                '<span style="color:red">$1</span>'
              );
          }
        }

        this.rightIndex = res.rightModel.actualPageIndex;
        this.leftIndex = res.leftModel.actualPageIndex;
        this.rightTitle = res.rightModel.documentTitle;
        this.leftTitle = res.leftModel.documentTitle;

        if (this.content.rightModel.content) {
          this.content.rightModel.content = this.content.rightModel.content
            .replace(/︵/g, "(")
            .replace(/︶/g, ")")
            .replace(/︽/g, "《")
            .replace(/︾/g, "》")
            .replace(/﹃/g, "『")
            .replace(/﹄/g, "』");
        }
        if (this.content.leftModel.content) {
          this.content.leftModel.content = this.content.leftModel.content
            .replace(/︵/g, "(")
            .replace(/︶/g, ")")
            .replace(/︽/g, "《")
            .replace(/︾/g, "》")
            .replace(/﹃/g, "『")
            .replace(/﹄/g, "』")
            .replace(/﹁/g, "「")
            .replace(/﹂/g, " 」");
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.close();
      }
    },
    handleNodeClick(bookIndex, data, id = null) {
      console.log(data, "data");
      var pageIndex = parseInt(data?.pageIndex || data);
      this.ebookIndex = bookIndex;
      this.ebookId = id;
      this.cutWidth = this.EBookData[bookIndex].cutWidth || 0;
      $("#magazine").turn("destroy");
      this.HangChang(bookIndex, this.EBookData[bookIndex], function () {
        $("#magazine").turn("page", pageIndex + 1);
      });
      this.activeId = id;
    },
    /* turn初始化 */
    async turnInit(item, callback) {
      var initImgList = [];
      if (item.pages.length > 20) {
        initImgList = item.pages.slice(0, 20);
      } else {
        initImgList = item.pages;
      }
      this.isViewShow = true;
      let that = this;
      this.allPages.push(
        "/api/fileinfo/ebook/GetEBookFaceForDynamic?path=" + item.facePath
      );
      if (that.ispicType == "0") {
        this.allPages.push("");
      }
      // if (this.$route.query.index <= 38) {
      // await this.ForPages(initImgList, item.isSingle);
      // }

      $("#magazine").turn("center");
      $("#magazine").turn("page");
      this.$nextTick(() => {
        $("#magazine").turn({
          display: "double",
          acceleration: false,
          gradients: true,
          autoCenter: true,
          duration: 1000,
          direction: "rtl",
          page: 1,
          pages: that.mPageCount,
          when: {
            turning: function (event, page, view) {
              // 清除选框
              that.cancelCon();
            },
            turned: function () {
              that.getEBookContent($(this).turn("view")[0] - 1);
              that.pageIndex = $(this).turn("view")[0] - 1;
              that.totalPage = $("#magazine").turn("pages") - 2;
              if ($(this).turn("view")[0] == "0") {
                that.placeholderStr = "封面";
              } else if (
                $(this).turn("view")[0] == $("#magazine").turn("pages")
              ) {
                that.placeholderStr = "封底";
              } else {
                that.placeholderStr =
                  $(this).turn("view")[0] -
                  1 +
                  " / " +
                  ($("#magazine").turn("pages") - 2);
              }
              let pagesArray = $(this).turn("view");
              pagesArray.forEach((pageitem) => {
                that.searchData.forEach((titem) => {
                  if (pageitem > 2 && pageitem - 3 == titem.pageIndex) {
                    $(
                      ".page-wrapper[page=" +
                        (titem.pageIndex + 3) +
                        "]>.ebook-search-page",
                      "#magazine"
                    ).remove();
                    var $page = $(
                      ".page-wrapper[page=" + (titem.pageIndex + 3) + "]",
                      "#magazine"
                    );
                    if ($page.length == 1) {
                      var html = '<div class="ebook-search-page">';
                      titem.words.forEach((sitem, index) => {
                        let rect = {
                          location: JSON.parse(JSON.stringify(sitem.location)),
                        };
                        if (titem.pageIndex % 2 == 0) {
                          rect.location.left -= titem.width / 2;
                        } else {
                          rect.location.left -= 250;
                        }
                        html +=
                          '<a class="ebook-search-mask" title="' +
                          sitem.word +
                          '"style="left:' +
                          Math.floor(rect.location.left * that.scaleIndex) +
                          "px;top:" +
                          Math.floor(rect.location.top * that.scaleIndex) +
                          "px;width:" +
                          Math.floor(rect.location.width * that.scaleIndex) +
                          "px;height:" +
                          Math.floor(rect.location.height * that.scaleIndex) +
                          'px">' +
                          "</a>";
                      });
                      html += "</div>";
                      $(html).appendTo($page);
                    }
                  }
                });
              });
            },
            missing: function (e, pages) {
              for (let i = 0; i < pages.length; i++) that.addPage(pages[i]);
            },
            start: function (e) {
              that.isDisable = false;
            },
            end: function (e) {
              that.isDisable = true;
              that.isShowImg = true;
            },
          },
        });
        if (callback && $.isFunction(callback)) {
          callback();
        }
        this.flag = true;
      });
    },
    // 清除搜索
    // cleanKeyword() {
    //   this.searchResList = [];
    //   this.keyword = "";
    //   this.$router.push({
    //     path: "/ebook",
    //     query: {
    //       sourceID: this.$route.query.sourceID,
    //       bookId: this.$route.query.bookId,
    //     },
    //   });
    // },
    addPage(pageIndex) {
      if (pageIndex <= this.mPageCount) {
        if (pageIndex == this.mPageCount - 1) {
          if (this.EBookItem.picType == "0") {
            var element = $(
              `<div><div data-index='${pageIndex}' style='margin: auto;border-right:2px solid #ccc ;text-align: start;background-size: 100% 100%;width: 100%;height: 100%;background-color: #fff;text-align: center;'></div></div>`
            );
          } else {
          }
          // 封底
        } else if (pageIndex == this.mPageCount) {
          var element = $(
            `<div><div data-index='${pageIndex}' style='margin: auto;border-right:2px solid #ccc ;text-align: start;background-size: 100% 100%;width: 100%;height: 100%;background-color: #fff;'><img  src=/api/fileinfo/ebook/GetEBookBackFace?path=` +
              this.EBookItem.facePath +
              " style='max-width: 100%;max-height: 125%;object-fit:contain;margin: auto;pointer-events: none;user-select: none;'/></div></div>"
          );
        } else {
          var element = $(
            `<div><div data-index='${pageIndex}' style='margin: auto;border-right:2px solid #ccc ;text-align: start;background-size: 100% 100%;width: 100%;height: 100%;background-color: #fff;'><img  src=/api/fileinfo/ebook/GetEBookPageForOriImg?fileName=` +
              this.pagesData[Math.floor((pageIndex - 3) / 2)].filePath +
              "/" +
              this.pagesData[Math.floor((pageIndex - 3) / 2)].fileName +
              "&&pageIndex=" +
              (pageIndex - 3) +
              "&&outputWatermask=" +
              this.outputWatermark +
              " style='max-width: 100%;max-height: 100%;object-fit:contain;margin: auto;pointer-events: none;user-select: none;'/></div></div>"
          );
        }
        $("#magazine").turn("addPage", element, pageIndex);
      }
    },
    /* 获取电子书本数 */
    async getEBook(SourceID) {
      this.loading = true;
      try {
        const res = await getEbook({
          SourceID,
          PageSize: this.ePageSize,
          PageIndex: this.ePageIndex,
        });

        this.EBookData = res.items;
        this.ebookId = this.EBookData[this.ebookIndex].id;
        this.cutWidth = this.EBookData[this.ebookIndex].cutWidth || 0;
        this.EBookData.forEach((v) => {
          let arr = _.groupBy(v.eBookNav, "parentNav");
          for (var v1 of v.eBookNav) {
            v1.children = arr[v1.id];
          }
          v.treeData = arr[""];
        });
        // this.treeExpandData = [
        //   this.EBookData[this.ebookIndex].treeData[this.ebookIndex].id,
        // ];
        this.eTotals = res.totalCount;
        this.ispicType = res.items[this.ebookIndex].picType;
        this.isSingle = res.items[this.ebookIndex].isSingle;
        this.outputWatermark = res.items[this.ebookIndex].outputWatermark;
        this.HangChang(this.ebookIndex, this.EBookData[this.ebookIndex]);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    /* 上一张 */
    searchPre() {
      if (Math.abs(this.translateX) > 0) {
        this.translateX += 125;
      }
    },
    /* 下一张 */
    searchNext() {
      if (Math.abs(this.translateX) < (this.transData.length - 6) * 125) {
        this.translateX -= 125;
      }
    },
    searchDClick(item, tindex) {
      $("#magazine").turn("page", this.searchData[tindex].pageIndex + 3);
      $(
        ".page-wrapper[page=" +
          (this.searchData[tindex].pageIndex + 3) +
          "]>.ebook-search-page",
        "#magazine"
      ).remove();
      var $page = $(
        ".page-wrapper[page=" + (this.searchData[tindex].pageIndex + 3) + "]",
        "#magazine"
      );
      if ($page.length == 1) {
        var html = '<div class="ebook-search-page">';
        this.searchData[tindex].words.forEach((sitem, index) => {
          let rect = {
            location: JSON.parse(JSON.stringify(sitem.location)),
          };
          if (this.searchData[tindex].pageIndex % 2 == 0) {
            rect.location.left -= this.searchData[tindex].width / 2;
          } else {
            rect.location.left -= 250;
          }
          html +=
            '<a class="ebook-search-mask" title="' +
            sitem.word +
            index +
            '"style="left:' +
            Math.floor(rect.location.left * this.scaleIndex) +
            "px;top:" +
            Math.floor(rect.location.top * this.scaleIndex) +
            "px;width:" +
            Math.floor(rect.location.width * this.scaleIndex) +
            "px;height:" +
            Math.floor(rect.location.height * this.scaleIndex) +
            'px">' +
            sitem.word +
            "</a>";
        });
        html += "</div>";
        $(html).appendTo($page);
      }
    },
    /* 点击左侧电子书列表 */
    async HangChang(index, item, callback) {
      this.$nextTick(() => {
        this.pageHeight = this.$refs.bodycontent.offsetHeight * 0.9;
        this.scaleIndex = this.pageHeight / item.pageHeight;
        this.pageWidth = (this.pageHeight / item.pageHeight) * item.pageWidth;
      });
      this.transData = [];
      this.translateX = 0;
      this.ebookIndex = index;
      this.pagesData = item.pages;
      if (item.picType == "0") {
        this.mPageCount = item.pages.length * 2 + 4;
      }
      if (item.picType == "1") {
        this.mPageCount = item.pages.length * 2 + 2;
      }

      if ($("#magazine").turn("is")) {
        $("#magazine").turn("destroy");
        this.allPages = [];
      }

      this.EBookItem = item;
      this.$nextTick(() => {
        this.turnInit(item, callback);
      });
    },
    ForPages(pages, single) {
      /**
       * 查看靠后的图片会很慢，因为要等这20页请求完成，我并不知道为什么要加载前面的20页？
       */
      return;
      var that = this;
      console.log(this.EBookItem.picType);
      pages.forEach((item, index) => {
        if (this.EBookItem.picType == "0") {
          for (let i = 0; i <= 1; i++) {
            that.allPages.push(
              "/api/fileinfo/ebook/GetEBookPageForOriImg?fileName=" +
                item.filePath +
                "/" +
                item.fileName +
                "&&pageIndex=" +
                (single ? index : index * 2 + i) +
                "&&outputWatermask=" +
                this.EBookItem.outputWatermark
            );
          }
        } else if (this.EBookItem.picType == "1") {
          for (let i = 1; i >= 0; i--) {
            // alert(i + "i");
            that.allPages.push(
              "/api/fileinfo/ebook/GetEBookPageForOriImg?fileName=" +
                item.filePath +
                "/" +
                item.fileName +
                "&&pageIndex=" +
                (single ? index : index * 2 + i) +
                "&&outputWatermask=" +
                this.EBookItem.outputWatermark
            );
          }
        }
      });
    },
    /* 上一页 */
    prePage() {
      try {
        $("#magazine").turn("previous");
      } catch (error) {}
    },
    /* 下一页 */
    nextPage() {
      $("#magazine").turn("next");
    },
    /* 跳转到特定页面 */
    blurOut() {
      if (isNaN(this.paginationNum)) return;
      if (this.paginationNum * 1 > this.totalPage) {
        $("#magazine").turn("page", +this.totalPage);
      } else if (this.paginationNum * 1 < 1) {
        $("#magazine").turn("page", 1);
      } else {
        console.log("this.paginationNum", this.paginationNum);
        $("#magazine").turn("page", this.paginationNum * 1 + 2);
      }
      this.paginationNum = null;
    },
    gotoPage(page) {
      if ($("#magazine") && page > 0) {
        $("#magazine").turn("page", page);
      }
    },
    /* 跳转第一页 */
    firstPage() {
      $("#magazine").turn("page", 1);
    },
    /* 跳转最后一页 */
    lastPage() {
      $("#magazine").turn("page", this.mPageCount);
    },
    /* 放大缩小 */
    turnZoom() {
      this.cancelCon();
      if (this.scaleValue == 1) {
        this.isDisable = false;
        this.scaleValue++;
        $("#magazine").turn("zoom", 2);
        // $("#magazine").addClass("zoom-out");
      } else {
        this.isDisable = true;
        this.scaleValue--;
        $("#magazine").turn("zoom", 1);
        // $("#magazine").removeClass("zoom-out");
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
/* WebKit 内核浏览器 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background: #9cc3ce;
  border-radius: 10px;
}

/* 其他浏览器 */
* {
  scrollbar-width: auto;
  scrollbar-color: #9cc3ce #f2f2f2;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background: #9cc3ce;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

.pages {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;

  .left {
    background: #812f12;
    height: 100%;
    overflow-y: auto;
    transition: 0.3s ease;
    // 隐藏滚动条
    scrollbar-width: none;
    -ms-overflow-style: none;

    .showname {
      font-size: 14px;
      overflow: hidden !important; // 溢出部分隐藏
      white-space: nowrap !important; //禁止自动换行
      text-overflow: ellipsis !important; // 使溢出部分以省略号显示
      display: block !important;
    }

    .title {
      background: #75290e;
      color: #fff;
      line-height: 48px;
      // padding-left: 24px;

      h3 {
        margin: 0;
        overflow: hidden !important; // 溢出部分隐藏
        white-space: nowrap !important; //禁止自动换行
        text-overflow: ellipsis !important; // 使溢出部分以省略号显示
        display: block !important;
      }
    }
    ::v-deep .el-tree {
      background: #812f12;
      color: #fff;

      // 超出的文字显示省略号
      .el-tree-node__label {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-tree-node:focus > .el-tree-node__content {
        color: #edb122;
        background: #812f12;
      }
      .el-tree-node.is-current > .el-tree-node__content {
        color: #edb122;
        background: #812f12;
      }
      .el-tree-node__content:hover,
      .el-upload-list__item:hover {
        color: #edb122;
        background: #812f12;
      }
      .el-tree-node:focus > .el-tree-node__content {
        // color: #edb122;
        background: #812f12;
      }
      .el-icon-caret-right:before {
        color: #02b3be;
      }

      // 展开时三角图标颜色
      .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
        color: #02b3be;
      }
      // 未展开时三角图标颜色
      .el-icon-caret-right:before {
        color: #02b3be;
      }

      // 没有子节点时三角图标颜色（一般不显示）
      .el-tree-node__expand-icon.is-leaf::before {
        color: rgba(0, 0, 0, 0); // 透明
      }
    }
  }
  .right {
    display: flex;
    background: #d3d3d3;
    // width: 100%;
    width: 85%;

    .zk-img {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      z-index: 999;
    }

    .book_box {
      // width: 100%;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: auto;

      .img_box {
        margin-bottom: 10px;

        span {
          display: inline-block;
          width: 114px;
        }
        .view {
          cursor: pointer;
          max-height: 100%;
          max-width: 100%;
          vertical-align: middle;
        }
        .search {
          margin-right: 200px;
          width: 300px;
          margin: 0 auto;

          margin: 0 150px;
          ::v-deep .el-input__suffix {
            display: flex;
            align-items: center;
          }
          ::v-deep .el-input__inner:focus {
            border: 1px solid transparent !important;
          }
        }
      }

      .book {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // overflow: hidden;

        #magazine {
          width: 100%;
          height: 100%;
        }
        // .zoom-out {
        //   transform: translate(-25%, -25%);
        // }

        .body-content {
          position: relative;
          box-sizing: border-box;
          height: 800px;
          margin: 0 auto;
          overflow: hidden;

          .dropwindow {
            border-radius: 10px;
            width: 150px;
            height: 60px;
            background: rgb(255, 255, 255);
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            display: none;
            box-shadow: 2px 2px 5px #666;
            border: 1px solid #aab4b4;
            transform: scale(1);
            padding: 15px 0;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            -o-user-select: none;
            user-select: none;
          }

          .drop-list {
            line-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            flex: 1;
            color: black;
            cursor: pointer;
            transform: scale(1);
            font-size: 16px;
          }
          .drop-list:hover {
            background: #ccc;
          }

          .text1 {
            box-sizing: border-box;
            position: relative;
            box-sizing: border-box;
            margin: auto;
            background-size: 100% 100%;
            height: 100%;
            background-color: #fff;
            text-align: center;
          }
          .text1:nth-child(1) {
            border-right: 2px solid #ccc;
          }
        }
        .left_btn {
          cursor: pointer;
          background: #9cc3ce;
          width: 62px;
          height: 90px;
          opacity: 0.1;
          position: absolute;
          top: 692px;
          left: 15%;
          z-index: 999999;
          transform: translateX(-50%);
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 20px;
            color: #fff;
          }
        }
        .right_btn {
          cursor: pointer;
          background: #9cc3ce;
          width: 62px;
          height: 90px;
          opacity: 0.1;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 692px;
          right: 15%;
          z-index: 999999;
          transform: translateX(50%);
          height: 90px;
          i {
            font-size: 20px;
            color: #fff;
          }
        }
        .box {
          width: 745px;
          height: 90px;
          overflow: hidden;
          position: absolute;
          top: 692px;
          left: 50%;
          z-index: 99999;
          transform: translateX(-50%);
          .image_box {
            position: absolute;
            left: 0;
            white-space: nowrap;
            // position: absolute;
            // top: 735px;
            // left: 50%;
            // z-index: 99999;
            // transform: translateX(-50%);
            // background: pink;

            li {
              display: inline-block;
              width: 70px;
              height: 90px;
              opacity: 0.1;
              margin-right: 5px;

              img {
                object-fit: fill;
                width: 100%;
                height: 100%;
              }
            }
            li:last-child {
              margin: 0;
            }
            li:hover {
              opacity: 1;
            }
          }
        }

        .pagination {
          box-sizing: border-box;
          margin: 0 auto;
          margin-top: 20px;
          // width: 400px;
          z-index: 9;
          display: flex;
          align-items: center;
          height: 50px;
          //   background-color: #fff;
          .pagination-item {
            cursor: pointer;
            box-sizing: border-box;
            // width: 53px;
            width: 100px;
            height: 100%;
            line-height: 50px;
            font-size: 26px;
            text-align: center;
            margin-right: 3px;
            border-radius: 5px;
            background: #fff;
            color: #d3d3d3;
          }
          ::v-deep .pagination-input {
            width: 100px;
            height: 50px;
            margin-right: 3px;
            text-align: center;
            line-height: 50px;
            border-radius: 5px;
            background: #fff;
            color: #d3d3d3;

            .el-input,
            .el-input__inner {
              height: 50px;
            }

            .el-input__inner:focus {
              border: 1px solid transparent !important;
            }
            //   margin-top: 4px;
            //   box-sizing: border-box;
            //   padding: 0 20px;
            //   flex: 1;
            //   align-items: center;
          }
        }
        .pagination1 {
          box-sizing: border-box;
          margin: 0 auto;
          margin-top: 20px;
          width: 400px;
          z-index: 9;
          display: flex;
          align-items: center;
          height: 30px;
          //   background-color: #fff;
          .pagination-item {
            cursor: pointer;
            box-sizing: border-box;
            width: 53px;
            height: 100%;
            line-height: 30px;
            font-size: 26px;
            text-align: center;
            margin-right: 3px;
            border-radius: 5px;
            background: #fff;
            color: #d3d3d3;
          }
          ::v-deep .pagination-input {
            width: 100px;
            height: 30px;
            margin-right: 3px;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
            background: #fff;
            color: #d3d3d3;

            .el-input,
            .el-input__inner {
              height: 30px;
            }

            .el-input__inner:focus {
              border: 1px solid transparent !important;
            }
            //   margin-top: 4px;
            //   box-sizing: border-box;
            //   padding: 0 20px;
            //   flex: 1;
            //   align-items: center;
          }
        }
      }
    }

    .text_box {
      width: 20%;
      // width: 550px !important;
      height: 100%;
      background: #f5f5f5;
      padding: 9px 9px 0;
      overflow: auto;
      .text {
        position: relative;
        padding-top: 52px;
        // width: 590px;
        // height: calc(100% - 121px);
        height: 100%;
        background: #fff;
        display: flex;
        justify-content: flex-end;

        ::v-deep .operation {
          padding-right: 26px;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          height: 50px;
          font-size: 18px;
          color: #000;
          display: flex;
          border-bottom: 1px solid #ccc;
          align-items: center;
          justify-content: flex-end;

          .el-radio__label {
            font-size: 18px;
            color: #000;
          }

          .el-radio__inner {
            width: 18px;
            height: 18px;
          }
        }
        .test1 {
          text-align: start;
          overflow-y: auto;
          font-size: 20px;
          color: #000;
          writing-mode: vertical-lr;
          writing-mode: tb-rl;
          font-family: 宋体;
          white-space: pre-wrap;
          line-height: 22px;
          letter-spacing: 2px;
        }
        .test1:focus {
          transition: border linear 0.1s, box-shadow linear 0.2s;
          box-shadow: 0 0 10px #66afe9; //向外漫射程度1
          -moz-box-shadow: 0 0 10px #66afe9; //向外漫射程度2
          -webkit-box-shadow: 0 0 10px #66afe9; //向外漫射程度3
          border: 1px solid #66afe9;
          outline: 0;
          padding: 9px;
        }

        .test2 {
          text-align: start;
          overflow-y: auto;
          font-size: 20px;
          color: #000;
          // writing-mode: vertical-lr;
          // writing-mode: tb-rl;
          font-family: 宋体;
          white-space: pre-wrap;
          line-height: 22px;
          letter-spacing: 2px;
        }
      }
      .btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 60px;
        border-top: 1px solid #cacaca;
        background: #fff;
        padding-right: 20px;

        .el-button {
          width: 128px;
          height: 40px;
          background: #9cc3ce;
          font-size: 16px;
          color: #fff;
        }
        ::v-deep .el-button:focus {
          border: 1px solid transparent !important;
        }
      }
    }
  }
}
</style>
  
  <style scoped>
.browser {
  display: flex;
  width: 100%;
  height: 100%;
}
.phone {
  display: flex;
  height: 100%;
}
.ebook-search-page {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999999;
}
</style>
  