<template>
  <div class="box" style="z-index: 999999999">
    <el-dialog
      v-dialogdrag
      title="局部段落"
      :visible="rangeDialogVisible"
      :close-on-click-modal="false"
      width="40%"
      @close="$emit('update:range-dialog-visible', false)"
    >
      <span class="content">
        <span v-show="content.content" v-html="content.content"> </span>
      </span>
      <div slot="footer" class="dialog-footer">
        <el-button style="background: #9cc3ce; color: #fff" @click="doCopy"
          >一键复制</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

    <script>
export default {
  props: {
    "range-dialog-visible": {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      tempMessage: "",
    };
  },
  methods: {
    doCopy(val) {
      this.$copyText(this.content.content).then(
        (res) => {
          this.$message.success("复制成功");
        },
        (e) => {
          this.$message.error("复制失败");
        }
      );
    },
  },
};
</script>
    
    <style lang="scss" scoped>
::v-deep .el-dialog__wrapper {
  // eslint-disable-line
  .el-dialog {
    height: 78vh;
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
      height: 30px;
      width: 35px;
    }
    .el-dialog__header {
      background: #9cc3ce;
    }
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn .el-dialog__close:hover {
      color: gray;
    }
  }
}

.content {
  font-size: 20px;
  color: #000;
  font-family: 宋体;
  line-height: 30px;
  letter-spacing: 2px;
  display: inline-block;
  height: 58vh;
  overflow: auto;
}
</style>
  